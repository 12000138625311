import React, { useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import Modal from 'react-modal';
import FileUpload from './FileUpload';
import './RequestDataSection.css';
import Authenticate from './Authenticate';
import EmailCapture from './EmailCapture';

// Add this line to set the app element for accessibility
Modal.setAppElement('#root');

const RequestDataSection = () => {
  const { isAuthenticated } = useAuth0();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  return (
    <section className="request-data-section">
      <div className="content">
        <div className="headerName">PrimeInsights DAO</div>

        {isAuthenticated ? (
          <>
            <FileUpload />
            <Authenticate />
          </>
        ) : (
          <>
            <h2>YOUR AMAZON DATA HAS EARNING POTENTIAL</h2>
            <div className="steps">
              <div className="step">
                <div className="step-numb">STEP 1</div>
                <button className="cta-button">Request Your Data</button>
              </div>
              <div className="step">
                <div className="step-numb">STEP 2</div>
                <button className="cta-button" onClick={openModal}>Contribute Your Data</button>
              </div>
            </div>
            <p>A community owned data pool, governed by data contributors.</p>
          </>
        )}
       
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Email Capture Modal"
        className="email-capture-modal"
        overlayClassName="email-capture-overlay"
      >
        <EmailCapture closeModal={closeModal} />
      </Modal>
    </section>
  );
};

export default RequestDataSection;
