import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  const navigate = useNavigate();

  return (
    <div className="privacy-policy-section">
      <button className="back-button" onClick={() => navigate('/')}>Back to Home</button>
      <h1>Privacy Policy and Terms & Conditions of Use for PrimeInsights DAO</h1>
      <div className="policy-content">
        <h2_privacy>Introduction</h2_privacy>
        <p_privacy>Welcome to PrimeInsights DAO. These Terms of Use ("Terms"), along with our Privacy Policy, comprehensively govern your access to and use of our digital platforms, including the primary website located at www.primeinsightsdao.com and all associated services, tools, resources, and communities (collectively referred to as the "Sites"). These Terms are legally binding agreements between you (the user) and PrimeInsights DAO, and by accessing, browsing, or otherwise using the Sites, you acknowledge that you have read, understood, and agree to be bound by these Terms.</p_privacy>

        <h2_privacy>Agreement to Terms</h2_privacy>
        <p_privacy>By utilizing the Sites, engaging with our content, or by simply browsing the Sites, you affirmatively accept these Terms and agree to adhere to all conditions set forth herein. If you represent an entity, you confirm that you have the authority to bind that entity to these Terms. If you do not accept these Terms in their entirety, you must not access or use the Sites.</p_privacy>

        <h2_privacy>Collective Governance</h2_privacy>
        <p_privacy>PrimeInsights DAO operates on a model of collective governance. All members contribute to decision-making processes and adhere to governance principles as determined by the governance rules of the community. Your participation in PrimeInsights DAO indicates your commitment to this governance model and the rules set forth thereby.</p_privacy>

        <h2_privacy>Usage Restrictions and Prohibited Conduct</h2_privacy>
        <p_privacy>By using the Sites provided by PrimeInsights DAO, you agree to comply with the following restrictions and prohibitions. Violation of these terms not only breaches our community trust but may also result in legal consequences. You understand and agree that you are personally responsible for your behavior on the Sites and agree to indemnify and hold harmless PrimeInsights DAO, its members, and affiliates from any claims, damages, losses, liabilities, costs, and expenses, including but not limited to attorney's fees, that arise from your actions or content you provide, any violation of these Terms, or any infringement by you of anyone's rights. This indemnification includes any form of legal claim, suit, or action alleging that your use was improper in any manner stated below:</p_privacy>
        <ul>
          <li><strong>Illegal or Harmful Conduct:</strong> You must not use the Sites and Services for any unlawful activities or in ways that could harm PrimeInsights DAO, its members, or any third party. Activities that are harmful, deceptive, or fraudulent are strictly prohibited.</li>
          <li><strong>Security Breaches:</strong> Attempting to breach or circumvent any security or authentication measures put in place by PrimeInsights DAO is forbidden. This includes accessing data not intended for you, logging into a server or account that you are not expressly authorized to access, or probing the security of the Sites and Services.</li>
          <li><strong>Malicious Software:</strong> You must not distribute viruses or any other types of malicious software that may harm PrimeInsights DAO or the interests or property of PrimeInsights DAO users. The introduction of any form of harmful or disruptive technology is against the Terms of Use.</li>
          <li><strong>Infringement of Rights:</strong> Your use of the Sites and Services must not infringe upon or violate the intellectual property rights or privacy rights of others. This includes, but is not limited to, uploading, posting, or otherwise transmitting content without having the right to do so due to intellectual property, confidentiality, privacy, or other proprietary restrictions.</li>
        </ul>
        <p_privacy>This indemnification will remain in effect even after your use of the Sites and Services ends or your relationship with PrimeInsights DAO is terminated. It's essential to understand that your actions have consequences and adhering to these guidelines is crucial for maintaining the integrity and security of the PrimeInsights DAO community and its resources.</p_privacy>

        <h2_privacy>Investigation of Misconduct</h2_privacy>
        <p_privacy>PrimeInsights DAO members reserve the right to investigate alleged or apparent misconduct, violations of these Terms, or misuse of the Sites or Services. PrimeInsights DAO may take any appropriate action in response to any misconduct or violation of these Terms, at its sole discretion. Actions may include, but are not limited to, suspension or termination of your access to the Sites and Services, reporting to law enforcement or other authorities, and taking legal action.</p_privacy>

        <h2_privacy>Intellectual Property</h2_privacy>
        <p_privacy>All content, including text, graphics, logos, and software, provided on the Sites is owned by PrimeInsights DAO or its licensors and is protected by copyright and intellectual property laws. You are granted a limited, non-exclusive license to access and use the Sites and Services for your personal, non-commercial use, subject to these Terms.</p_privacy>

        <h2_privacy>Dispute Resolution</h2_privacy>
        <p_privacy>Any disputes or claims arising out of or in connection with your use of the Sites, or these Terms, including any disputes relating to the existence, validity, interpretation, performance, breach, or termination thereof, will be exclusively resolved through binding arbitration in accordance with the commercial arbitration rules of the American Arbitration Association. By agreeing to these Terms, you expressly waive any right you may have to initiate or participate in a class action lawsuit or class-wide arbitration against PrimeInsights DAO. Individual arbitration is the sole and exclusive forum for resolving any such disputes, claims, or controversies.</p_privacy>

        <h2_privacy>Governing Law</h2_privacy>
        <p_privacy>These Terms shall be governed by and construed in accordance with the laws of the State of Wyoming, without giving effect to any principles of conflicts of law. By using the Sites, you consent to the exclusive jurisdiction of the state and federal courts located in Wyoming for any claim or dispute arising out of or related to these Terms.</p_privacy>

        <h2_privacy>Amendments and Modifications</h2_privacy>
        <p_privacy>PrimeInsights DAO reserves the right to amend or modify these Terms at any time. Such amendments will be effective immediately upon posting on the Sites. Your continued use of the Sites after the posting of revised Terms constitutes your acceptance of the changes.</p_privacy>

        <h2_privacy>Termination</h2_privacy>
        <p_privacy>PrimeInsights DAO reserves the right to terminate your access to the Sites and Services without notice, for any reason whatsoever.</p_privacy>

        <p_privacy>By using the Sites, you acknowledge that you have read these Terms, understand them, and agree to be bound by their terms and conditions.</p_privacy>
      
      </div>
  
    <div className="policy-policy-section">
    <h1>Privacy Policy</h1>
      <div className="policy-content">
        <h2_privacy>Introduction</h2_privacy>
        <p_privacy>Welcome to PrimeInsights DAO, a Decentralized Autonomous Organization (DAO) that operates through our official website, www.primeinsightsdao.com, and its associated platforms or communities. This Privacy Policy outlines our commitment to responsibly manage, use, process, and protect personal data within our decentralized structure, in full compliance with the General Data Protection Regulation (GDPR) and other pertinent data protection laws.</p_privacy>

        <h2_privacy>Data Collection and Use</h2_privacy>
        <p_privacy>We collect various types of data, such as Personal Identification Information, Web Activity Data, and financial and payments data, to support DAO operations, enhance user experiences, and ensure the security of our platform. Our practices are guided by principles of lawfulness, fairness, transparency, and purpose limitation.</p_privacy>
        <p_privacy>When using the Chrome Extension, PrimeInsights DAO collects anonymized browsing data and user interaction data solely to provide personalized content and optimize functionality. This data is not shared with third parties except as necessary to provide our services or comply with legal requirements.</p_privacy>

        <h2_privacy>Member Responsibility and Data Sharing</h2_privacy>
        <p_privacy>As an unincorporated entity, PrimeInsights DAO places the responsibility for GDPR compliance and data management on its individual members. Members must exercise caution and uphold privacy standards when sharing data within PrimeInsights DAO and its associated platforms, maintaining our collective commitment to data protection.</p_privacy>
        <p_privacy>PrimeInsights DAO is committed to GDPR compliance and ensures that all data processing activities within our platforms adhere to data protection standards. While members are encouraged to uphold privacy best practices, the DAO assumes primary responsibility for the lawful management of data collected through its website and associated platforms, including the Chrome Extension.</p_privacy>

        <h2_privacy>Security Measures</h2_privacy>
        <p_privacy>Data security is a shared responsibility among all PrimeInsights DAO members. We advocate for and implement robust security measures, such as encryption and access controls, to safeguard personal information against unauthorized access and data breaches.</p_privacy>
        <p_privacy>PrimeInsights DAO implements robust security measures, including encryption and access controls, to protect user data transmitted through the Chrome Extension. We ensure that all user information is stored securely and is only accessible by authorized members of the DAO, following industry-standard data protection protocols.</p_privacy>
        <p_privacy>PrimeInsights DAO takes data security seriously and is committed to implementing the necessary technical and organizational measures to protect user data against unauthorized access or breaches. We encourage members to practice good data stewardship, complementing the security efforts implemented by the DAO.</p_privacy>

        <h2_privacy>Your Rights Under GDPR</h2_privacy>
        <p_privacy>Members have rights to data access, correction, deletion, processing restriction, portability, and objection. Requests related to these rights should be directed to our established communication channels, as we aim to collaboratively address and honor such requests in alignment with GDPR requirements.</p_privacy>

        <h2_privacy>Consent and Policy Modifications</h2_privacy>
        <p_privacy>By engaging with PrimeInsights DAO through www.primeinsightsdao.com and our associated platforms, you consent to this Privacy Policy. We reserve the right to modify this policy as necessary, with a commitment to inform members of significant changes.</p_privacy>

        <h2_privacy>GDPR Inquiries and Requests</h2_privacy>
        <p_privacy>For inquiries and requests related to GDPR compliance, please contact us via the communication channels specified on www.primeinsightsdao.com. Our structured approach ensures efficient and compliant handling of personal data-related concerns.</p_privacy>

        <h2_privacy>Contact Us</h2_privacy>
        <p_privacy>For any questions about this Privacy Policy or our data protection practices, please reach out through our website, www.primeinsightsdao.com. We periodically review and update this policy to reflect current data protection laws and our operational practices, ensuring our members are always informed of our latest data protection measures. This document was last updated on 9 October 2024.</p_privacy>

      </div>
    </div>
    </div>
  );

}

export default PrivacyPolicy;