import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './EmailCapture.css';

const EmailCapture = ({ closeModal }) => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const BASE_URL = 'https://vana-backend-production.up.railway.app/'; 
    const url = `${BASE_URL}/email`;
    const headers = { 'Content-Type': 'application/json' };
    const data = { email: email };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      });

      if (response.ok) {
        console.log('Email submitted successfully:', email);
        setSubmitted(true);
      } else {
        console.error('Failed to submit email:', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting email:', error);
    }
  };

  return (
    <div className="email-capture-content">
      <h2>Enter Your Email</h2>
      <p>We will notify when you can contribute your data.</p>
      {!submitted ? (
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
            className="email-input"
          />
          <div className="button-container">
            <button type="submit" className="submit-button">Next</button>
            <p className="privacy-policy-link">
              By clicking "Next", you agree to our <Link to="/privacy-policy" className="privacy-policy-link-2">Privacy Policy</Link>.
            </p>
          </div>
        </form>
      ) : (
        <p className="success-message">Upload coming soon!</p>
      )}
    </div>
  );
};

export default EmailCapture;
