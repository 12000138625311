import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "./SignInComponent.css";

const SignInComponent = () => {
  const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();

  const handleLogin = () => {
    loginWithRedirect();
  };

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  return (
    <div className="modalContainer">
      <section className="signInContainer">
        <div className="signInWrapper">

          {isAuthenticated ? (
            
            <div className="welcomeMessage">
              <header className="signInHeader">
                <h1 className="signInTitle">Signed in as, {user.name}</h1>
              </header>
              <button onClick={handleLogout} className="signInOption signOutButton">
                <span className="optionText">Sign Out</span>
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/2a71368e95e382ab2aff7b1593a1e3892c5b9bc4270b43d191f64c8c68c5e197?placeholderIfAbsent=true&apiKey=8f15f1cba7ad436f99a72da82afc6e11"
                  alt=""
                  className="arrowIcon"
                />
              </button>
            </div>
          ) : (
            <>
              <header className="signInHeader">
                <h1 className="signInTitle">Sign In</h1>
              </header>
              <nav className="signInOptions">
                {/* 
                <button onClick={handleLogin} className="signInOption">
                  <img
                    src="https://cdn.auth0.com/styleguide/components/1.0.8/media/logos/img/logo-grey.png"
                    alt="Auth0 Logo"
                    className="optionIcon"
                  />
                  <span className="optionText">Continue with Auth0</span>
                  <img
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/2a71368e95e382ab2aff7b1593a1e3892c5b9bc4270b43d191f64c8c68c5e197?placeholderIfAbsent=true&apiKey=8f15f1cba7ad436f99a72da82afc6e11"
                    alt=""
                    className="arrowIcon"
                  />
                </button>
                */}
                <p className="signInOption">Coming Soon</p>
              </nav>
              <p className="agreementText">
                PrimeInsights <a href="https://www.primeinsightsdao.com/privacy-policy"><strong>Terms and Privacy Policy</strong></a>
                <strong>Terms & Privacy Policy</strong> and the Vana Network{" "}
                <a href="https://www.vana.org/legal/privacy-policy"><strong>Terms and Privacy Policy</strong></a>
              </p>
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export default SignInComponent;