import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Leaderboard.css';

function Leaderboard() {
  const navigate = useNavigate();
  
  const dummyData = [
    { rank: 1, username: 'JohnDoe', dataUploadPoints: 500, referralPoints: 500, totalPoints: 1000 },
    { rank: 2, username: 'JaneSmith', dataUploadPoints: 450, referralPoints: 500, totalPoints: 950 },
    { rank: 3, username: 'BobJohnson', dataUploadPoints: 600, referralPoints: 300, totalPoints: 900 },
    { rank: 4, username: 'AliceBrown', dataUploadPoints: 400, referralPoints: 450, totalPoints: 850 },
    { rank: 5, username: 'CharlieDavis', dataUploadPoints: 350, referralPoints: 450, totalPoints: 800 },
  ];

  return (
    <div className="leaderboard-section">
      <button className="back-button" onClick={() => navigate('/')}>Back to Home</button>
      <h1>Leaderboard</h1>
      <div className="leaderboard-table">
        <table>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Username</th>
              <th>Data Upload Points</th>
              <th>Referral Points</th>
              <th>Total Points</th>
            </tr>
          </thead>
          <tbody>
            {dummyData.map((player) => (
              <tr key={player.rank}>
                <td>{player.rank}</td>
                <td>{player.username}</td>
                <td>{player.dataUploadPoints}</td>
                <td>{player.referralPoints}</td>
                <td>{player.totalPoints}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Leaderboard;