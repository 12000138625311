import React from 'react';
import './HowItWorksSection.css'; 

const HowItWorksSection = () => {
  return (
    <section className="how-it-works-section">
      <h2>How it Works & Why it's Important</h2>
      <div className="cards">
        <div className="card">
          <div className="card-header">Join with Ease</div>
          <div className="card-content">We all have Amazon accounts, but we don’t benefit from the data we generate. With PrimeInsights DAO, you can change that. Simply request your Amazon data, receive your data via email, submit your zip file and start earning data points based on your purchase history.</div>
        </div>
        <div className="card">
          <div className="card-header">Unlock the Value of your Data</div>
          <div className="card-content">Amazon generates billions in revenue from user data, leveraging it for targeted advertising and market insights. By contributing your data to PrimeInsights DAO, you can reclaim a share of this value. Your purchase history and preferences are valuable assets that can be used for market research, AI training, and more.</div>
        </div>
        <div className="card">
          <div className="card-header">Take Control of Your Data</div>
          <div className="card-content">You have the right to access and control your Amazon data, thanks to regulations like GDPR and CCPA. By joining PrimeInsights DAO, you can collectively pool your data into a secure treasury managed on the Vana Network. Decisions on data usage are made democratically, ensuring transparency and fairness.</div>
        </div>
        <div className="card">
          <div className="card-header">Benefit from Your Contributions</div>
          <div className="card-content">Instead of Amazon reaping all the benefits, PrimeInsights DAO allows you to earn from your data. Members can vote to sell data to market research firms or AI companies. Additionally, contributors can choose to build and train member-owned AI models, deciding collectively how to monetize these models. The more high quality data you contribute, the greater your share of the rewards.</div>
        </div>
      </div>
    </section>
    
  );
};

export default HowItWorksSection;
