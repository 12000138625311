import React from 'react';
import './Footer.css'; // Import your styling

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="left-content">
          <div className="contract-info">
            <h3>Smart Contract</h3>
            <p>Address coming soon...</p>
          </div>
          <div className="privacy-policy">
            <a href="/privacy-policy">Terms and Privacy Policy</a>
          </div>
        </div>
        <div className="built-on">
          <p>Built on <span className="vana">Vana</span></p>
          <a href="https://x.com/primedatadao"><img src={require('../images/x.webp')} alt="X logo" className="x-logo enlarged-logo" /></a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
