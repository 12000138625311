import React, { useState } from 'react';
import './Authenticate.css';

function Authenticate() {
  const [step, setStep] = useState('request');
  const [url, setUrl] = useState('');
  const [status, setStatus] = useState('');
  const [validatorInfo, setValidatorInfo] = useState(null);

  const handleRequestVerify = async () => {
    // Simulate requesting a TEE validator
    setStatus('Requesting TEE validator...');
    setTimeout(() => {
      setValidatorInfo({
        id: 'TEE-123456',
        pubKey: 'simulated-public-key'
      });
      setStep('input');
      setStatus('Validator assigned. Please enter the URL.');
    }, 2000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Simulate encrypting and submitting the URL
    setStatus('Encrypting and submitting URL...');
    setTimeout(() => {
      setStatus('URL submitted successfully!');
      setUrl('');
      setStep('request');
      setValidatorInfo(null);
    }, 2000);
  };

  return (
    <div className="authenticate">
      <div className="authenticate-content">
        <h2>Authenticate URL</h2>
        {step === 'request' ? (
          <button onClick={handleRequestVerify} className="request-button">
            Request Verify
          </button>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="input-wrapper">
              <input
                type="text"
                id="url-input"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="Enter URL"
                required
              />
            </div>
            <button type="submit" className="submit-button">
              <span className="button-text">Submit URL</span>
            </button>
          </form>
        )}
        {status && <p className="status-message">{status}</p>}
        {validatorInfo && (
          <p className="validator-info">
            Validator ID: {validatorInfo.id}
          </p>
        )}
      </div>
    </div>
  );
}

export default Authenticate;