import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; 
import './Navbar.css'; 
import SignInComponent from './SignInComponent';
import { useAuth0 } from "@auth0/auth0-react";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user, isAuthenticated, isLoading, error, loginWithRedirect } = useAuth0();

  useEffect(() => {
    console.log("Auth state changed:");
    console.log("User:", user);
    console.log("Is Authenticated:", isAuthenticated);
    console.log("Is Loading:", isLoading);
    if (error) {
      console.error("Auth0 Error:", error);
      console.error("Error message:", error.message);
      console.error("Error stack:", error.stack);
    }
  }, [user, isAuthenticated, isLoading, error]);

  const handleLogin = async () => {
    try {
      await loginWithRedirect();
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const openModal = () => {
    setIsModalOpen(true);
    setIsMenuOpen(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <header className="navbar">
        <div className="navbar-content">
          {isAuthenticated && (
              <span className="user-name">{user.name}</span>
          )}
          <div className="navbar-right">
            <div className="hamburger-menu" onClick={toggleMenu}>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className={`menu-dropdown ${isMenuOpen ? 'open' : ''}`}>
              <button className="menu-item" onClick={openModal}>Sign In</button>
              <Link to="/leaderboard" className="menu-item" onClick={toggleMenu}>Leaderboard</Link>
            </div>
          </div>
        </div>
      </header>
      {isModalOpen && (
        <>
          <div className="modal-overlay" onClick={closeModal}></div>
          <div className="modal">
            <SignInComponent />
          </div>
        </>
      )}
    </>
  );
};

export default Navbar;