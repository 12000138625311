import React, { useState, useRef } from 'react';
import './FileUpload.css';

function FileUpload() {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const formRef = useRef(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      setUploadStatus('Please select a file to upload.');
      return;
    }

    const formData = new FormData(formRef.current);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', 'https://fudlp.wildsage.io/upload');
    xhr.timeout = 3600000; // 1 hour timeout

    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        let message = `${xhr.status}: ${xhr.statusText}`;
        if (xhr.status === 0) message = 'Connection failed';
        if (xhr.status === 204) {
          message = `Success: ${xhr.statusText}`;
        }
        setUploadStatus(message);
      }
    };

    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        const percentComplete = Math.floor((e.loaded / e.total) * 100);
        setUploadProgress(percentComplete);
        setUploadStatus(
          e.loaded === e.total
            ? 'Saving...'
            : `${percentComplete}% [${Math.floor(e.loaded / 1024)} / ${Math.floor(e.total / 1024)}KiB]`
        );
      }
    };

    xhr.onerror = () => {
      setUploadStatus('Error uploading file. Please try again.');
    };

    xhr.send(formData);
  };

  return (
    <div className="file-upload">
      <div className="upload-content">
        <h2>Upload Your File</h2>
        <form ref={formRef} onSubmit={handleSubmit}>
          <div className="file-input-wrapper">
            <input type="file" id="file-input" name="files" onChange={handleFileChange} />
            <label htmlFor="file-input" className="file-input-label">
              {file ? file.name : 'Choose a file or drag it here'}
            </label>
          </div>
          <button type="submit" className="upload-button">Upload</button>
        </form>
        {uploadStatus && (
          <div className={`upload-status ${uploadStatus.includes('Success') ? 'success' : 'error'}`}>
            <div className={` ${uploadStatus.includes('Success') ? 'success' : 'error'}`}>{uploadStatus}</div>
            {uploadProgress > 0 && uploadProgress < 100 && (
              <progress value={uploadProgress} max="100"></progress>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default FileUpload;