import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Auth0Provider } from "@auth0/auth0-react";
import Navbar from './components/Navbar';
import RequestDataSection from './components/RequestDataSection';
import WatchHowSection from './components/WatchHowSection';
import HowItWorksSection from './components/HowItWorksSection';
import Footer from './components/Footer';
import Leaderboard from './components/Leaderboard';
import PrivacyPolicy from './components/PrivacyPolicy';
function App() {
  console.log("Auth0 Domain:", process.env.REACT_APP_AUTH0_DOMAIN);
  console.log("Auth0 Client ID:", process.env.REACT_APP_AUTH0_CLIENT_ID);

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <Router>
        <div className="App">
          <Navbar />
          <Routes>
            <Route path="/" element={
              <>
                <RequestDataSection />
                <WatchHowSection />
                <HowItWorksSection />
              </>
            } />
            <Route path="/leaderboard" element={<Leaderboard />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </Auth0Provider>
  );
}

export default App;