import React from 'react';
import './WatchHowSection.css'; // Import your styling

const WatchHowSection = () => {
  return (
    <section className="watch-how-section">
      <h2>How to Request Your Data</h2>
      <div className="how-content">
        <div className="how-image-container">
          <img 
            src={require('../images/upload.png')} 
            alt="Request your details" 
            className="how-image"
          />
        </div>
        <div className="how-video-container">
          <iframe 
            src="https://www.youtube.com/embed/_LTYTdzkcAE?si=2JJk4Sh2pHGmDBI9" 
            title="How to Download Your Amazon Data" 
            allowFullScreen
            className="how-video"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default WatchHowSection;
